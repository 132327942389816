var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var defaultRouteConfig = {
    allowDesktop: false,
    allowAnonymous: false,
    allowInvalidDeal: false,
    redirectWhenRefresh: false,
    fetchSession: true,
    fetchApp: true,
    allowDirect: true,
    fallbackPage: 'pageNotFound',
    redirectWhenCustomerHasLoan: true,
    webViewGuard: false,
    fetchDeal: false,
};
export var buildRouteConfig = function (config) {
    if (config === void 0) { config = {}; }
    return __assign(__assign({}, defaultRouteConfig), config);
};
export var preLoginRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    redirectWhenCustomerHasLoan: false,
});
export var anonymousRouteConfig = buildRouteConfig({
    allowAnonymous: true,
});
export var noDocInputOtpRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: false,
    fallbackPage: 'SelectProductV2',
    redirectWhenCustomerHasLoan: false,
});
export var noDocOtpExclusiveRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: false,
    webViewGuard: true,
    redirectWhenCustomerHasLoan: false,
});
export var applyingRouteConfig = buildRouteConfig();
export var validateRefreshConfig = buildRouteConfig({
    redirectWhenRefresh: true,
});
export var noDocPostSubmissionRouteConfig = buildRouteConfig({
    allowDirect: false,
    fallbackPage: 'SelectProductV2',
    fetchDeal: true,
});
export var noDocPostThirdPartySubmissionRouteConfig = buildRouteConfig({
    redirectWhenCustomerHasLoan: false,
    allowAnonymous: true,
    allowDirect: false,
    fallbackPage: 'SelectProductV2',
});
export var noDocPostThirdPartySubmissionRouteConfigAllDevice = buildRouteConfig({
    redirectWhenCustomerHasLoan: false,
    allowAnonymous: true,
    allowDirect: false,
    allowDesktop: true,
    fallbackPage: 'SelectProductV2',
});
export var noDirectRouteConfig = buildRouteConfig({
    allowDirect: false,
    fallbackPage: 'pageNotFound',
    redirectWhenCustomerHasLoan: false,
});
export var allowThirdPartyRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: false,
    fallbackPage: 'pageNotFound',
    redirectWhenCustomerHasLoan: false,
});
export var allowThirdPartyRouteConfigAllDevice = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: false,
    allowDesktop: true,
    fallbackPage: 'pageNotFound',
    redirectWhenCustomerHasLoan: false,
});
export var allowAllRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDesktop: true,
    redirectWhenCustomerHasLoan: false,
});
export var submissionFailedRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: false,
    fallbackPage: 'pageNotFound',
    redirectWhenCustomerHasLoan: false,
    fetchSession: false,
    fetchApp: false,
});
export var missingDataRouteConfig = buildRouteConfig({
    allowDirect: true,
    redirectWhenCustomerHasLoan: false,
});
export var dealRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDesktop: false,
    allowInvalidDeal: true,
    allowDirect: true,
    redirectWhenCustomerHasLoan: false,
    fetchDeal: true,
});
export var preLoginRouteConfigNoDoc = buildRouteConfig({
    allowAnonymous: true,
    redirectWhenCustomerHasLoan: true,
});
export var validateRefreshConfigMissingData = buildRouteConfig({
    allowAnonymous: true,
    redirectWhenRefresh: true,
    allowDirect: true,
    fallbackPage: 'MissingData',
    redirectWhenCustomerHasLoan: true,
});
export var missingDataRouteConfigNoDoc = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: true,
    redirectWhenCustomerHasLoan: true,
});
export var noDirectRouteConfigNoDoc = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: true,
    redirectWhenCustomerHasLoan: true,
    fallbackPage: 'thankYou',
});
export var validateCrmConfigMissingData = buildRouteConfig({
    allowAnonymous: true,
    allowDirect: false,
    webViewGuard: true,
    fallbackPage: 'productHighlight',
    redirectWhenCustomerHasLoan: true,
});
export var crmPreApprovedRouteConfig = buildRouteConfig({
    allowDirect: false,
    fallbackPage: 'DealList',
    fetchDeal: true,
});
export var allowDeirectRouteConfig = buildRouteConfig({
    allowAnonymous: true,
    allowDesktop: false,
    redirectWhenCustomerHasLoan: false,
    fetchDeal: true,
});
